import React, { useEffect } from "react";
import { Button, Box, Typography, Paper } from "@mui/material";

const FullScreenContainer = ({ children, isFullScreen, setIsFullScreen }) => {
  const enterFullScreen = () => {
    const elem = document.documentElement;

    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    }
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
      // Force back to fullscreen if the user exits fullscreen mode
      enterFullScreen();
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      // Re-enter fullscreen if the user switches tabs or windows
      enterFullScreen();
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Attempt to enter fullscreen on component mount
    enterFullScreen();

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );

      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: isFullScreen ? "none" : "transparent",
        // Apply blur to the entire content if not fullscreen
      }}
    >
      {/* Background Blur Effect */}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute", // Ensure it covers the whole area
          top: 0,
          left: 0,
          backdropFilter: isFullScreen ? "none" : "blur(5px)", // Apply blur to background content
          pointerEvents: isFullScreen ? "auto" : "none", // Make content unclickable when not fullscreen
          zIndex: isFullScreen ? "auto" : 1, // Ensure background blur is beneath Paper
        }}
      >
        {children}
      </Box>

      {/* Floating Paper Component */}
      {!isFullScreen && (
        <Paper
          elevation={3}
          sx={{
            position: "relative", // Ensure it's on top of the blurred content
            padding: "20px",
            borderRadius: "15px",
            textAlign: "center",
            maxWidth: "400px",
            zIndex: 100000, // Ensure it's above the blurred content
          }}
        >
          <Typography variant="h5" fontWeight={"bold"} gutterBottom>
            Full-Screen Mode Required
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please enter full-screen mode to access the exam. You are not
            allowed to proceed without enabling full-screen.
          </Typography>
          <Button
            onClick={enterFullScreen}
            color="warning"
            variant="contained"
            sx={{
              borderRadius: "50px",
              padding: "10px 40px",
              marginTop: "20px",
            }}
          >
            Enter Fullscreen
          </Button>
        </Paper>
      )}
    </Box>
  );
};

export default FullScreenContainer;
