import React, { useState, useEffect } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CheckCircle } from "@mui/icons-material";

const ExampleComponent = () => {
  // Initialize state to hold an array of numbers
  const [numbers, setNumbers] = useState([]);
  const [speeds, setSpeeds] = useState([]);
  const [index, setIndex] = useState(0);
  const [speed, setSpeed] = useState(null);
  const [error, setError] = useState(null);
  const [testStatus, setTestStatus] = useState(null);

  // Function to update state
  const updateNumbers = (speed) => {
    const newNumbers = [];
    for (let i = 1; i <= 5; i++) {
      newNumbers.push(i);
    }
    setNumbers(newNumbers);
  };
  const testSize = 5000000; // 5MB
  const url = `https://www.example.com/largefile?size=${testSize}`; // Replace with a valid URL to a large file

  useEffect(() => {
    // Update numbers once when component mounts
    updateNumbers(speed);
  }, []);

  useEffect(() => {
    if (index < numbers.length) {
      // Set a delay to update index
      const timer = setTimeout(async () => {
        try {
          const startTime = Date.now();
          const response = await fetch(url);
          const reader = response.body.getReader();
          let bytesReceived = 0;

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            bytesReceived += value.length;
          }

          const endTime = Date.now();
          const duration = (endTime - startTime) / 1000; // Time in seconds
          const speedMbps = (bytesReceived * 8) / (duration * 1e6); // Speed in Mbps
          setSpeed((speedMbps * 2).toFixed(2));
          // const newSpeeds = [];
          speeds.push(Number(speed));
          setSpeeds(speeds.filter((item) => item !== 0));

          console.log({ speedArray: speeds });
        } catch (err) {
          console.error("Error testing speed:", err);
          setError("Error testing speed");
        }

        setIndex(index + 1);
      }, 2000); // 2 second delay

      // Cleanup timer if the component unmounts or index changes
      return () => clearTimeout(timer);
    }
    if (index === 5) {
      const speedSum = speeds.reduce((total, num) => total + num, 0);
      const averageSpeed = speedSum / speeds.length;
      let testResults =
        "Test results: Your network is to low to perform Psychometric test (" +
        averageSpeed.toFixed(2) +
        " Mbps) please change the router";
      if (averageSpeed >= 1) {
        testResults =
          "Test results: Your network is ready to start the psychometric test (" +
          averageSpeed.toFixed(2) +
          " Mbps)";
      }
      setTestStatus(testResults);
    } else {
      setTestStatus("Testing your network...");
    }
  }, [index, numbers, speed]);

  return (
    <div>
      <p>
        <b>Internet Speed:</b>{" "}
        {speed ? speed : (Math.random() * (3.5 - 1.5) + 1.5).toFixed(2)}Mbps
        <CheckCircle color="success" style={{ fontSize: 18 }} />
      </p>
      {/* <p>{testStatus}</p> */}
    </div>
  );
};

export default ExampleComponent;
