import { useEffect } from "react";
import Test from "./components/Test";

const TestComponent = ({
  totalScore,
  setTotalScore,
  onSubmitTest,
  onCancel,
  advertisement,
}) => {
  // const openFullscreenWindow = () => {
  //   // Define window features for minimal UI
  //   const windowFeatures =
  //     "width=" +
  //     window.screen.width +
  //     ",height=" +
  //     window.screen.height +
  //     ",resizable=yes,scrollbars=no,location=no,toolbar=no,menubar=no,status=no";

  //   // Open the new window
  //   const newWindow = window.open(
  //     "/", // You can specify a URL here if you want to open a specific page
  //     "_blank",
  //     windowFeatures
  //   );

  //   // // Add content to the new window
  //   // newWindow.document.write(
  //   //   "<h1>Fullscreen Window</h1><p>This is a fullscreen window with no tabs or address bar.</p>"
  //   // );
  //   // newWindow.document.body.style.display = "flex";
  //   // newWindow.document.body.style.justifyContent = "center";
  //   // newWindow.document.body.style.alignItems = "center";
  //   // newWindow.document.body.style.height = "100vh";
  //   // newWindow.document.body.style.margin = "0";
  //   // newWindow.document.body.style.fontFamily = "Arial, sans-serif";
  //   // newWindow.document.body.style.fontSize = "24px";

  //   // Request fullscreen mode if supported
  //   // newWindow?.document.documentElement.requestFullscreen();
  // };

  // Check for VMware
  const isVMware = () => {
    const canvas = document.createElement("canvas");
    const gl =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

    if (gl) {
      const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
      const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
      const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

      return (
        vendor.toLowerCase().includes("vmware") ||
        renderer.toLowerCase().includes("vmware")
      );
    }
    return false;
  };

  // Check if is virtual
  const isVirtualEnvironment = () => {
    const isLowCPU = navigator.hardwareConcurrency <= 2;
    const isVMUserAgent =
      navigator.userAgent.includes("VMware") ||
      navigator.userAgent.includes("VirtualBox");
    const isVMWebGL = isVMware(); // As defined in the WebGL check example above

    return isLowCPU || isVMUserAgent || isVMWebGL;
  };

  useEffect(() => {
    // Virtual control
    if (isVirtualEnvironment()) {
      alert("This application cannot run in virtual environments.");
      window.location.href = "/"; // Redirect or block access
    }
    // Opening full screen window
    // openFullscreenWindow();
  }, []);

  return (
    <Test
      totalScore={totalScore}
      setTotalScore={setTotalScore}
      onSubmitTest={onSubmitTest}
      onCancel={onCancel}
      advertisement={advertisement}
    />
  );
};

export default TestComponent;
