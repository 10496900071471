import { Button } from "@mui/material";
import React, { useState } from "react";
import Webcam from "react-webcam";
import "./validate.css";
import { Container, Row, Col } from "react-bootstrap";
import SystemCheck from "../systemcheck/systemCheeck";
import Detection from "../../Detections/Detections";

const ValidatePage = ({ setImageSrc, step }) => {
  var buttonfield = true;
  //Disable Right click
  if (document.addEventListener) {
    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
  }
  // document.addEventListener("visibilitychange", handleVisibilityChange, false);
  //for capturing image
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [errorCounts, setErrorCounts] = useState();
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setImageSrc(imageSrc);
    sessionStorage.setItem("imageSrc", imageSrc);
  }, [webcamRef, setImgSrc]);

  //image as base64
  //console.log(imgSrc);

  if (imgSrc) {
    buttonfield = false;
  }

  // /* View in fullscreen */
  // function openFullscreen() {
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //     history.push("/instructions")
  //   } else if (elem.webkitRequestFullscreen) { /* Safari */
  //     elem.webkitRequestFullscreen();
  //     history.push("/instructions")
  //   } else if (elem.msRequestFullscreen) { /* IE11 */
  //     elem.msRequestFullscreen();
  //     history.push("/instructions")
  //   }
  // }
  function handleClick() {
    //history.push("/systemcheck");
  }
  return (
    <div className="App-header">
      <Container fluid>
        {step === 4 ? (
          <>
            <div style={{ marginBottom: "10px" }}>
              Camera detection: Place your face in the center
            </div>
            <Row>
              {!imgSrc && <Col sm={3}></Col>}
              <Col sm={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <center>
                  <Webcam
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"300px"}
                    style={{
                      borderRadius: "10px",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      left: 138,
                      top: 40,
                      marginLeft: "80px",
                    }}
                  >
                    <Detection
                      errorCounts={errorCounts}
                      setErrorCounts={setErrorCounts}
                    ></Detection>
                  </div>
                </center>
                <Button
                  id="validateButtons"
                  variant="outlined"
                  color="primary"
                  onClick={capture}
                  style={{ marginTop: "7px", marginLeft: "180px" }}
                >
                  Detect Face
                </Button>
              </Col>
              <Col sm={imgSrc ? 6 : 4}>
                <center>
                  {imgSrc && (
                    <img
                      src={imgSrc}
                      style={{ width: "300px", borderRadius: "10px" }}
                    />
                  )}
                  {imgSrc && (
                    <div className="overlay">
                      <p className="image-text">Face detected</p>
                    </div>
                  )}
                </center>
                {/* <Button
              id="validateButtons"
              disabled={buttonfield}
              variant="contained"
              onClick={handleClick}
              style={{ marginTop: "10px" }}
            >
              Validate Face
            </Button> */}
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col>
              <SystemCheck />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ValidatePage;
