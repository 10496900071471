import { useEffect, useState } from "react";
import axios from "axios";
import CountdownTimer from "../helper/CountdownTimer";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import "./CustomPsyCss.css";
import ProctoringContainer from "../ProctoringContainer/ProctoringContainer";
import HandleMatching from "./handleQuestionTypes/HandleMatching";
import HandleFillMissing from "./handleQuestionTypes/HandleFillMissing";
import VoiceActivityDetection from "./voiceDetection/VoiceActivityDetection";
import translateLanguage from "../helper/TranslateLanguage";

const Test = ({ setTotalScore, onSubmitTest, onCancel, advertisement }) => {
  const [testData, setTestData] = useState(null);
  const [isDone, setIsDone] = useState([]);
  const [error, setError] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [advertisementId, setAdvertisementId] = useState(null);
  const [preferredLanguage, setPreferredLanguage] = useState(null);
  const [existingTestData, setExistingTestData] = useState(null);

  const getaTest = async (
    language,
    advertisementId,
    existingTestDataDetails
  ) => {
    setLoading(true);
    setPreferredLanguage(language);
    let preferredLanguageId = null;
    language === "English"
      ? (preferredLanguageId = 1)
      : language === "French"
      ? (preferredLanguageId = 2)
      : (preferredLanguageId = 3);

    console.log({ advertisementId: advertisementId });
    let data1 = {
      advertisementId: advertisementId,
      preferredLanguageId: preferredLanguageId,
      psySession:
        existingTestDataDetails === null || existingTestDataDetails.length === 0
          ? ""
          : existingTestDataDetails[0].id,
    };

    try {
      const { data: response } = await axios.post(
        "/api/psychometric-test/startthetest",
        data1
      );

      if (response) {
        console.log(response);
        const newdata = response.map((item, i) => {
          if (item.questionScore !== undefined && item.questionScore !== null)
            setIsDone((prev) => [...prev, item.id]);
          return {
            ...item,
            i: i + 1,
          };
        });
        setTestData(newdata);
        setSelectedQuestion(
          newdata.find(
            (itm) =>
              itm.questionScore === undefined || itm.questionScore === null
          ) || null
        );
        setError("");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("Failed to load test data");
      setLoading(false);
    }
  };

  const saveAnswer = async () => {
    if (testData) {
      console.log({
        selectedQuestion,
        compared: testData[testData.length - 1],
      });
      if (!selectedQuestion) return;
      if (selectedQuestion.id === testData[testData.length - 1].id) {
        if (
          window.confirm("Are you sure do you want to submit the test?") ===
          false
        ) {
          return;
        }
      }
      setLoading(true);
      const answeredQuestion = testData.map((data) => {
        if (data.id === selectedQuestion.id) {
          try {
            const theCorrectOption = data.options.find(
              (itm) => itm.isCorrect
            ).id;
            const markedScore =
              selectedOption === theCorrectOption ? data.score : 0;
            setTotalScore((prevScore) => prevScore + markedScore);

            // Making an API call to mark the question
            axios
              .post("/api/psychometric-test/markaquestion", {
                testSessionId: data.testSessionId,
                questionId: data.id,
                score: markedScore,
              })
              .then(() => {
                // After submitting the data, then
                setTestData(answeredQuestion);
                setIsDone((prev) => [...prev, selectedQuestion.id]);

                const nextItem = testData[selectedQuestion.i] || null;
                setSelectedQuestion(null);
                setTimeout(() => setSelectedQuestion(nextItem), 10);
                if (nextItem === null) {
                  onSubmitTest((testData ?? []).length);
                }
                setLoading(false);
              })
              .catch((err) => setLoading(false));

            return { ...data, answer: selectedOption };
          } catch (err) {
            setLoading(false);
            console.error("Error processing the question:", err);
          }
        }
        return data;
      });
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const isRadioButtonSelected = (optionId) => {
    return selectedQuestion?.answer === optionId || selectedOption === optionId;
  };

  const handleFinish = () => {
    saveAnswer();
    console.log("Countdown finished!");
  };

  const handleLanguageChange = (language, loadTest) => {
    console.log({
      handleLanguageChangeadvertisementId: advertisement.id,
    });
    // get the test according to the language chosen
    setAdvertisementId(advertisement.id);
    setSelectedLanguage(language);
    loadTest === true && getaTest(language, advertisement.id, existingTestData);
  };

  useEffect(() => {
    const handlePrevent = (e) => {
      e.preventDefault();
    };

    // Attach event listeners to document
    document.addEventListener("copy", handlePrevent);
    document.addEventListener("cut", handlePrevent);
    document.addEventListener("paste", handlePrevent);

    // Cleanup event listeners when component unmounts
    return () => {
      document.removeEventListener("copy", handlePrevent);
      document.removeEventListener("cut", handlePrevent);
      document.removeEventListener("paste", handlePrevent);
    };
  }, []);

  return (
    <ProctoringContainer
      selectedLanguage={selectedLanguage}
      handleLanguageChange={(language, loadTest) =>
        handleLanguageChange(language, loadTest)
      }
      onCancel={onCancel}
      advertisement={advertisement}
      setExistingTestData={(existingData) => {
        setExistingTestData(existingData);
        if (existingData.length > 0) {
          getaTest(
            existingData[0].preferredLanguageId.toString() === "3"
              ? "Kinyarwanda"
              : existingData[0].preferredLanguageId.toString() === "2"
              ? "French"
              : "English",
            advertisement.id,
            existingData
          );
        }
      }}
      existingTestData={existingTestData}
    >
      <div>
        <div
          className="col-12 p-0 pb-2 pt-2 pl-3 bg-white border-bottom"
          style={{ paddingTop: "16px" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="fs-2 text-primary">
              <b>Psychometric Test</b> | {advertisement.positionName}
            </div>
            <div className="d-flex justify-content-end">
              <div className="pr-2 mr-2 border-right">
                <div style={{ fontSize: "12px" }}>Count Down</div>
                {selectedQuestion && (
                  <div style={{ fontWeight: "bolder" }}>
                    <CountdownTimer
                      duration={selectedQuestion.questionTimeInSec}
                      onFinish={handleFinish}
                    />
                  </div>
                )}
              </div>
              <div className="pr-3">
                <div style={{ fontSize: "12px" }}>
                  {translateLanguage(
                    {
                      en: "Completed Questions",
                      fr: "Questions complétées",
                      kn: "Ibibazo byarangiye",
                    },
                    selectedLanguage
                  )}
                </div>
                <div style={{ fontWeight: "bolder" }}>
                  {selectedQuestion?.i ?? 0} of {testData?.length}
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading === true ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%", minHeight: "400px", width: "100%" }}
          >
            <h2>Loading, please wait...</h2>
          </div>
        ) : selectedQuestion === null ? (
          <div style={{ padding: "20px" }}>
            <div>
              Please, Click on the following <b>Confirm</b> button to Continue
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Button
                onClick={() => onSubmitTest((testData ?? []).length)}
                variant="contained"
                color="primary"
              >
                Click to continue
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="col-12 bg-light"
            style={{ padding: "80px", paddingBottom: "100px" }}
          >
            {selectedQuestion &&
              selectedQuestion.questionTypeId.toString() !== "3" && (
                <>
                  <div className="fw-bold mb-3" style={{ fontSize: "18px" }}>
                    Q{selectedQuestion?.i}.{" "}
                    {selectedLanguage === "English"
                      ? selectedQuestion.en
                      : selectedLanguage === "French"
                      ? selectedQuestion.fr
                      : selectedQuestion.kn}
                  </div>
                  <div className="mb-2" style={{ fontSize: "15px" }}>
                  {selectedLanguage === "English"
                      ? "Select only one:"
                      : selectedLanguage === "French"
                      ? "Sélectionnez une réponse:"
                      : "Hitamo igisubizo:"}
                      
                  </div>
                </>
              )}
            {/* Handle Fill Missing */}
            {selectedQuestion &&
              selectedQuestion.questionTypeId.toString() === "3" && (
                <HandleFillMissing
                  selectedQuestion={selectedQuestion}
                  selectedLanguage={selectedLanguage}
                  submitQuestionAnswer={(submittedDataMarks) => {
                    setLoading(true);
                    const answeredQuestion = testData.map((data) => {
                      if (data.id === selectedQuestion.id) {
                        try {
                          setTotalScore(
                            (prevScore) => prevScore + submittedDataMarks.score
                          );

                          // Making an API call to mark the question
                          axios
                            .post("/api/psychometric-test/markaquestion", {
                              testSessionId: data.testSessionId,
                              questionId: data.id,
                              score: submittedDataMarks.score,
                            })
                            .then(() => {
                              // After submitting the data, then
                              setTestData(answeredQuestion);
                              setIsDone((prev) => [
                                ...prev,
                                selectedQuestion.id,
                              ]);

                              const nextItem =
                                testData[selectedQuestion.i] || null;
                              setSelectedQuestion(null);
                              setTimeout(
                                () => setSelectedQuestion(nextItem),
                                10
                              );
                              if (nextItem === null) {
                                onSubmitTest((testData ?? []).length);
                              }
                              setLoading(false);
                            })
                            .catch((err) => setLoading(false));

                          return { ...data };
                        } catch (err) {
                          setLoading(false);
                          console.error("Error processing the question:", err);
                        }
                      }
                      return data;
                    });
                  }}
                  isLastQuestion={
                    testData !== null &&
                    testData.length > 0 &&
                    testData[selectedQuestion.i] === undefined
                  }
                />
              )}
            {/* Matching question type */}
            {selectedQuestion &&
              selectedQuestion.questionTypeId.toString() === "5" && (
                <HandleMatching
                  selectedQuestion={selectedQuestion}
                  selectedLanguage={selectedLanguage}
                  submitQuestionAnswer={(submittedDataMarks) => {
                    setLoading(true);
                    const answeredQuestion = testData.map((data) => {
                      if (data.id === selectedQuestion.id) {
                        try {
                          setTotalScore(
                            (prevScore) => prevScore + submittedDataMarks.score
                          );

                          // Making an API call to mark the question
                          axios
                            .post("/api/psychometric-test/markaquestion", {
                              testSessionId: data.testSessionId,
                              questionId: data.id,
                              score: submittedDataMarks.score,
                            })
                            .then(() => {
                              // After submitting the data, then
                              setTestData(answeredQuestion);
                              setIsDone((prev) => [
                                ...prev,
                                selectedQuestion.id,
                              ]);

                              const nextItem =
                                testData[selectedQuestion.i] || null;
                              setSelectedQuestion(null);
                              setTimeout(
                                () => setSelectedQuestion(nextItem),
                                10
                              );
                              if (nextItem === null) {
                                onSubmitTest((testData ?? []).length);
                              }
                              setLoading(false);
                            })
                            .catch((err) => setLoading(false));

                          return { ...data };
                        } catch (err) {
                          setLoading(false);
                          console.error("Error processing the question:", err);
                        }
                      }
                      return data;
                    });
                  }}
                  isLastQuestion={
                    testData !== null &&
                    testData.length > 0 &&
                    testData[selectedQuestion.i] === undefined
                  }
                />
              )}
            {/* Multiple choice */}
            {selectedQuestion &&
              (selectedQuestion.questionTypeId.toString() === "1" ||
                selectedQuestion.questionTypeId.toString() === "4") && (
                <div className="pl-4 mb-4">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      {selectedQuestion.options.map((option, i) => (
                        <FormControlLabel
                          key={i + 1}
                          value={option.id}
                          control={<Radio />}
                          label={`${option.optionNumber}) ${
                            selectedLanguage === "English"
                              ? option.en
                              : selectedLanguage === "French"
                              ? option.fr
                              : option.kn
                          }`}
                          checked={isRadioButtonSelected(option.id)}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              )}

            {selectedQuestion &&
              (selectedQuestion.questionTypeId.toString() === "1" ||
                selectedQuestion.questionTypeId.toString() === "4") && (
                <div
                  className="mt-2 bg-primary pl-4 pr-4 pt-2 pb-2 rounded-pill text-white"
                  style={{ width: "max-content", cursor: "pointer" }}
                  onClick={saveAnswer}
                >
                  {testData !== null &&
                  testData.length > 0 &&
                  testData[selectedQuestion.i] !== undefined
                    ? "Save & Continue"
                    : "Save & Submit the Test"}
                </div>
              )}
          </div>
        )}

        <VoiceActivityDetection />
      </div>
    </ProctoringContainer>
  );
};

export default Test;
