import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ duration, onFinish }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (onFinish) {
        onFinish();
      }
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onFinish]);

  return (
    <div id="countdown">
      {timeLeft <= 0 ? 'Finished' : `${timeLeft} seconds remaining`}
    </div>
  );
};

export default CountdownTimer;
