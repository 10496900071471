import React, { Fragment, useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { CheckCircle, Visibility, Phone, Error } from "@mui/icons-material";
import Detection from "../Detections/Detections";
import "./ProctoringContainer.css";
import { Box, Typography, Divider } from "@mui/material";
import ValidatePage from "../components/systemvalidation/ValidatePage";
import FullScreenContainer from "../components/fullScreenContainer/FullScreenContainer";

export default function ProctoringContainer({
  children,
  onCancel,
  handleLanguageChange,
  selectedLanguage,
  advertisement,
  existingTestData,
  setExistingTestData,
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const [errorCounts, setErrorCounts] = useState({
    multipleFaces: 0,
    cellPhone: 0,
    book: 0,
    laptop: 0,
    faceNotVisible: 0,
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [leftWindow, setLeftWindow] = useState(false);

  const [instructionsOpen, setInstructionsOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  // const [selectedLanguage, setSelectedLanguage] = useState(selectedLanguage); //English, French, Kinyarwanda
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleCloseInstructions = () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 2) {
      setCurrentStep(3);
    } else if (currentStep === 3) {
      setCurrentStep(4);
    } else {
      imageSrc === null
        ? window.alert("Please, click on detect face")
        : selectedLanguage !== null && setInstructionsOpen(false);
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const checkForExistingTest = async () => {
    setLoading(true);
    try {
      const { data: response } = await axios.get(
        `/api/psychometric-test/checkforexistingsession/${advertisement.id}`
      );

      if (response) {
        console.log({ testResponse: response });
        setExistingTestData(response);
        if (response.length > 0) {
          handleLanguageChange(
            response[0].preferredLanguageId.toString() === "3"
              ? "Kinyarwanda"
              : response[0].preferredLanguageId.toString() === "2"
              ? "French"
              : "English",
            false
          );
          setCurrentStep(2);
        }
        setLoading(false);
      }
    } catch (err) {
      setExistingTestData([]);
      console.log(err);
      setLoading(false);
    }
  };

  const disableCopyCutPaste = () => {
    const handlePrevent = (e) => {
      e.preventDefault();
    };

    // Attach event listeners to document
    document.addEventListener("copy", handlePrevent);
    document.addEventListener("cut", handlePrevent);
    document.addEventListener("paste", handlePrevent);

    // Cleanup event listeners when component unmounts
    return () => {
      document.removeEventListener("copy", handlePrevent);
      document.removeEventListener("cut", handlePrevent);
      document.removeEventListener("paste", handlePrevent);
    };
  };

  useEffect(() => {
    disableCopyCutPaste();
    existingTestData === null && checkForExistingTest();
  }, [existingTestData]);

  return (
    <Fragment>
      {/* WebCam and Detection Component Display */}
      <div
        style={{
          position: "fixed",
          top: 10,
          right: 10,
          zIndex: 9999999,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          style={{
            backgroundColor: "#f0f0f0",
            color: "#000",
            display: "none",
          }}
        >
          Show Monitored Actions
        </Button>
      </div>

      {/* Instructions dialog */}
      <Dialog
        open={instructionsOpen}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px", // Increase or decrease the value to make the corners more or less rounded
            border: 4,
            borderColor: "#dcf1ff",
            width: "1050px", // Set the desired width
            maxWidth: "none", // Disable the default maxWidth
          },
        }}
      >
        <DialogTitle
          style={{
            backgroundColor: "#d4eeff",
            fontSize: "15px",
            fontWeight: "normal",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "24px",
              fontWeight: "bold",
              color: "#1a76d2",
            }}
          >
            {advertisement.positionName}
          </div>
          <div
            style={{
              fontSize: "13px",
              marginTop: "-4px",
              marginBottom: "15px",
            }}
          >
            {advertisement.entityName}
          </div>
          {currentStep !== 1 && (
            <>
              <div style={{ fontWeight: "bold" }}>Exams to be conducted</div>
              <div
                style={{
                  padding: "5px",
                  paddingTop: "0px",
                  marginBottom: "-14px",
                }}
              >
                <div>
                  <Box sx={{ width: "100%" }}>
                    {/* First Row */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        px: 0,
                        py: 0.5,
                        fontSize: "3px",
                      }}
                    >
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "bolder",
                            color: "chocolate",
                          }}
                        >
                          Psychometric Test
                        </div>
                      </Typography>
                      <Typography variant="body1">
                        <div
                          style={{
                            fontSize: "13px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            backgroundColor: "beige",
                            borderRadius: "50px",
                            border: 4,
                            borderColor: "orangered",
                          }}
                        >
                          Pending to Start
                        </div>
                      </Typography>
                    </Box>
                    <Divider />

                    {/* Second Row */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        px: 0,
                        py: 1,
                        fontSize: "3px",
                      }}
                    >
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        <div style={{ fontSize: "14px" }}>Written</div>
                      </Typography>
                      <Typography variant="body1">
                        <div
                          style={{
                            fontSize: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            backgroundColor: "#ebebeb",
                            borderRadius: "50px",
                            color: "#777777",
                          }}
                        >
                          Not Done
                        </div>
                      </Typography>
                    </Box>
                    <Divider />

                    {/* Third Row */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        px: 0,
                        py: 1,
                        fontSize: "3px",
                      }}
                    >
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        <div style={{ fontSize: "14px" }}>Oral</div>
                      </Typography>
                      <Typography variant="body1">
                        <div
                          style={{
                            fontSize: "12px",
                            paddingLeft: "14px",
                            paddingRight: "14px",
                            paddingTop: "4px",
                            paddingBottom: "4px",
                            backgroundColor: "#ebebeb",
                            borderRadius: "50px",
                            color: "#777777",
                          }}
                        >
                          Not Done
                        </div>
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </div>
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <div style={{ paddingTop: "8px" }}>
            <div style={{ fontSize: "24px", fontWeight: "bolder" }}>
              Psychometric Test
            </div>
            <div
              style={{
                fontSize: "12.5px",
                marginTop: "-4px",
                marginBottom: "30px",
              }}
            >
              psychometric tests are assessments used to measure psychological
              attributes such as intelligence, aptitude, personality, and
              behavior.
            </div>
            <div style={{ fontWeight: "bolder" }}>
              {currentStep === 1 && (
                <div>{currentStep} of 3: Chose a test language</div>
              )}
              {currentStep === 2 && (
                <div>
                  {currentStep}
                  {selectedLanguage === "English"
                    ? " of 4: Checking the system and your computer"
                    : selectedLanguage === "French"
                    ? " du 4: Vérification du système et de votre ordinateur"
                    : " muri 4: Isuzuma rya sisitemu na mudasobwa yanyu"}
                </div>
              )}
              {currentStep === 3 && (
                <div>
                  {currentStep}
                  {selectedLanguage === "English"
                    ? " of 4: Psychometric Test rules"
                    : selectedLanguage === "French"
                    ? " du 4: Règles du test psychométrique"
                    : " muri 4: Amategeko y’ikizamini cya psychométrique"}
                </div>
              )}
              {currentStep === 4 && (
                <div>
                  {currentStep}
                  {selectedLanguage === "English"
                    ? " of 4: Camera Testing"
                    : selectedLanguage === "French"
                    ? " du 4: Camera Testing"
                    : " muri 4: Camera Testing"}
                </div>
              )}
            </div>
          </div>
          {currentStep === 1 && (
            <div>
              {currentStep === 1 && (
                <>
                  <div style={{ height: "20px" }}></div>
                  <RadioGroup
                    value={selectedLanguage}
                    onChange={(e) => handleLanguageChange(e.target.value, true)}
                  >
                    <FormControlLabel
                      value="English"
                      control={<Radio />}
                      label="English"
                    />
                    <FormControlLabel
                      value="French"
                      control={<Radio />}
                      label="French"
                    />
                    {/* <FormControlLabel
                      value="Kinyarwanda"
                      control={<Radio />}
                      label="Kinyarwanda"
                    /> */}
                  </RadioGroup>
                </>
              )}
            </div>
          )}
          {currentStep === 3 && (
            <div>
              {selectedLanguage === "English" && (
                <List>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Visibility color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Ensure you are alone in the room."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Phone color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="No mobile phones or other electronic devices are allowed."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Visibility color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="You must remain in view of the webcam throughout the test."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Error color="error" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Any attempt to cheat or tamper with the proctoring system will lead to disqualification."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <CheckCircle color="success" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Follow all on-screen instructions carefully."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                </List>
              )}
              {selectedLanguage === "French" && (
                <List>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Visibility color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Assurez-vous d’être seul dans la pièce."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Phone color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Les téléphones portables et autres appareils électroniques ne sont pas autorisés."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Visibility color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Vous devez rester dans le champ de la webcam pendant toute la durée du test."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Error color="error" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Toute tentative de tricherie ou de manipulation du système de surveillance entraînera une disqualification."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <CheckCircle color="success" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Suivez attentivement toutes les instructions à l’écran."
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                </List>
              )}
              {selectedLanguage === "Kinyarwanda" && (
                <List>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Visibility color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Mbere yo gutangira, reba ko uri wenyine mucyumba"
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Phone color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Kwifashisha telephone cg ibindi bikoresho byikorana buhanga ntibyemewe"
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Visibility color="action" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Mwirinde kujya hanze yijisho rya kamera"
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <Error color="error" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Kugerageza gukopera birahanirwa"
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                  <ListItem style={{ padding: "0px 0" }}>
                    <ListItemIcon>
                      <CheckCircle color="success" style={{ fontSize: 18 }} />
                    </ListItemIcon>
                    <ListItemText
                      primary="Mukurikize amabwiriza mwitonze"
                      primaryTypographyProps={{ fontSize: "13px" }} // Set font size to 12px
                    />
                  </ListItem>
                </List>
              )}
            </div>
          )}
          {(currentStep === 2 || currentStep === 4) && (
            <ValidatePage setImageSrc={setImageSrc} step={currentStep} />
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between", // Space between div and buttons
            width: "100%", // Ensures the full width is used
          }}
        >
          {/* Place this div on the left side */}
          <div style={{ paddingLeft: "10px" }}>
            <div style={{ fontSize: "10px" }}>
              {selectedLanguage === "Kinyarwanda"
                ? "Ibibazo byose hamwe"
                : selectedLanguage === "French"
                ? "Toute les questions"
                : "Total questions"}
            </div>
            <div
              style={{
                fontWeight: "bolder",
                fontSize: "24px",
                marginTop: "-8px",
              }}
            >
              21
            </div>
          </div>
          <div>
            <Button
              onClick={() =>
                currentStep === 1 ? onCancel() : setCurrentStep(1)
              }
              variant="contained"
              sx={{
                backgroundColor: "#f5f5f5", // Light background color
                color: "#000", // Ensure the text is visible on the light background
                borderRadius: "50px",
                paddingLeft: "30px",
                paddingRight: "30px",
                boxShadow: "none", // Remove the box shadow
                "&:hover": {
                  backgroundColor: "#e0e0e0", // Slightly darker shade on hover
                  boxShadow: "none", // Keep box shadow disabled on hover
                },
              }}
            >
              Back
            </Button>
            <Button
              onClick={handleCloseInstructions}
              color="primary"
              variant="contained"
              sx={{
                borderRadius: "50px",
                paddingLeft: "30px",
                paddingRight: "30px",
                boxShadow: "none",
                marginLeft: "10px", // Add some space between buttons
              }}
            >
              {currentStep < 4 ? (
                <span>Continue</span>
              ) : (
                <span>Accept and Start your Psychometric Test</span>
              )}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* Monitored actions dialog */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Monitored Actions and Errors</DialogTitle>
        <DialogContent>
          <ul>
            <li>Multiple Faces Detected: {errorCounts.multipleFaces}</li>
            <li>Cell Phone Detected: {errorCounts.cellPhone}</li>
            <li>Book Detected: {errorCounts.book}</li>
            <li>Laptop Detected: {errorCounts.laptop}</li>
            <li>Face Not Visible: {errorCounts.faceNotVisible}</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Displaying video */}
      {selectedLanguage !== null && instructionsOpen === false && (
        <div
          style={{
            position: "fixed",
            opacity: 9999999,
            right: 210,
            bottom: 240,
            zIndex: 9999999,
          }}
        >
          <header>
            <Detection
              errorCounts={errorCounts}
              setErrorCounts={setErrorCounts}
            ></Detection>
          </header>
        </div>
      )}

      <FullScreenContainer
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
      >
        {/* The exam component */}
        {selectedLanguage !== null && instructionsOpen === false && (
          <div
            style={{
              width: "100%",
              height: "100%",
              marginBottom: "-50px",
              filter: instructionsOpen ? "blur(5px)" : "none",
            }}
          >
            <div>{children}</div>
          </div>
        )}
      </FullScreenContainer>
    </Fragment>
  );
}
