import React, { useEffect, useRef, useState } from 'react';

// Configuration settings for the visualizer bars
const settings = {
  bars: 50,
  spacing: 6,
  width: 10,
  height: 150,
};

const VoiceActivityDetection = () => {
  const refs = useRef([]); // References to each bar DOM element
  const [volume, setVolume] = useState(0); // State to track the current volume level
  const [maxVolume, setMaxVolume] = useState(0); // State to track the maximum volume level
  const volumeRefs = useRef(new Array(settings.bars).fill(0)); // Array to hold volume levels for each bar
  const [hasPermission, setHasPermission] = useState(false); // State to track if microphone permission is granted

  // Function to get microphone access and set up audio processing
  const getMedia = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true }) // Request audio access
      .then((stream) => {
        setHasPermission(true);
        const audioContext = new AudioContext(); // Create a new AudioContext
        const analyser = audioContext.createAnalyser(); // Create an AnalyserNode for real-time frequency data
        const microphone = audioContext.createMediaStreamSource(stream); // Create a media source from the microphone
        const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1); // Create a script processor for custom audio processing

        analyser.smoothingTimeConstant = 0.4; // Smooth out the frequency data over time
        analyser.fftSize = 1024; // Set FFT size for frequency data resolution
        microphone.connect(analyser); // Connect the microphone to the analyser
        analyser.connect(javascriptNode); // Connect the analyser to the script processor
        javascriptNode.connect(audioContext.destination); // Connect the script processor to the audio context output

        // Function to process audio data and calculate volume levels
        javascriptNode.onaudioprocess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount); // Create an array to hold frequency data
          analyser.getByteFrequencyData(array); // Fill the array with frequency data
          let values = 0;
          const length = array.length;
          for (let i = 0; i < length; i++) {
            values += array[i]; // Sum all frequency data values
          }
          const avgVolume = values / length; // Calculate the average volume level
          setVolume(avgVolume);
          setMaxVolume((prevMax) => Math.max(prevMax, avgVolume));
          if(Math.round((prevMax) => Math.max(prevMax, avgVolume))>30){console.log('The system has detect unacceptable noice')}
        };
      })
      .catch((err) => {
        console.error('Microphone access denied:', err);
        setHasPermission(false);
      });
  };

  // Effect hook to initialize media access when the component mounts
  useEffect(getMedia, []);

  // Effect hook to update the visualizer bars based on the current volume
  useEffect(() => {
    const intervalId = setInterval(() => {
      volumeRefs.current.unshift(volume); // Add the current volume to the front of the volume array
      volumeRefs.current = volumeRefs.current.slice(0, settings.bars); // Keep only the most recent volume data for each bar

      // Update the transform scale of each bar based on the volume
      refs.current.forEach((ref, i) => {
        if (ref) {
          ref.style.transform = `scaleY(${volumeRefs.current[i] / 100})`; // Scale each bar's height proportionally to the volume
        }
      });
    }, 20); // Update every 20 milliseconds

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [volume]);

  // Function to create the visualizer bar elements
  const createElements = () => {
    let elements = [];

    for (let i = 0; i < settings.bars; i++) {
      elements.push(
        <div
          ref={(ref) => (refs.current[i] = ref)} // Assign the ref for each bar element
          key={`vu-${i}`} // Set a unique key for each bar
          style={{
            position: 'absolute',
            background: 'lightblue',
            borderRadius: settings.width + 'px',
            width: settings.width + 'px',
            height: Math.sin((i / settings.bars) * 4) * settings.height + 'px', // Calculate initial height based on sine function
            left: i * (settings.width + settings.spacing) + 'px', // Position each bar horizontally
            top:
              settings.height / 2 +
              Math.sin((i / settings.bars) * 4) * (-settings.height / 2) +
              'px', // Position each bar vertically
          }}
        />
      );
    }
    return elements; // Return the array of bar elements
  };

  // Render the component
  return (
    <div>
      {hasPermission ? ( // Display the visualizer and volume data if permission is granted
        <div style={{position: 'fixed', bottom: '200px', width: 'fit-conent', marginLeft: 'auto', marginRight: 'auto', left: '200px', right: '0'}}>
        
          
          <div style={{ position: 'relative'}}>{createElements()}</div>{' '}
          {/* Display the visualizer bars */}
        </div>
      ) : (
        <p>Waiting for microphone access...</p> // Display a message if microphone access is pending
      )}
    </div>
  );
};

export default VoiceActivityDetection;