import AdvertSkeleton from "./comm/AdvertSkeleton";
import { getRequisitionQualifications } from "../../../store/applicant/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

const AdvertisementQualifications = (props) => {
  const {
    user,
    loading,
    advertisement,
    requisitionQualifications,
    getRequisitionQualifications,
    isHome,
    educationQualified,
    setEducationQualified,
    env,
  } = props;

  useEffect(() => {
    getRequisitionQualifications(advertisement.requisitionId, user?.id || 0);
  }, []);

  useEffect(() => {
    setEducationQualified(
      !!requisitionQualifications.find(({ qualified }) => qualified === true)
    );
  }, [requisitionQualifications]);

  return (
    <>
      {loading && !requisitionQualifications.length && <AdvertSkeleton />}
      {!!requisitionQualifications.length && (
        <div className="mt-3">
          <div className=" mb-2">
            <strong>Minimum qualifications</strong>
            {!educationQualified &&
              !isHome &&
              !advertisement.advertisementId && (
                <span className="badge badge-success ml-1">
                  You are qualified! {/* you are not qualified badge-warning*/}
                </span>
              )}
          </div>

          <List
            className="mt-2"
            sx={{
              borderRadius: "8px",
              border: `1px solid #c8c6c6`,
              backgroundColor: "#f9f9f9",
            }}
            dense
          >
            {requisitionQualifications.map((qualification, index) => (
              <QualificationItem
                key={qualification.id}
                index={index}
                qualification={qualification}
                showDivider={requisitionQualifications.length > index + 1}
                isHome={isHome}
              />
            ))}
          </List>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  env,
  user,
  loading,
  requisitionQualifications,
}) => ({
  env,
  user,
  loading,
  requisitionQualifications,
});

export default connect(mapStateToProps, { getRequisitionQualifications })(
  AdvertisementQualifications
);

const QualificationItem = (props) => {
  const { index, qualification, showDivider, isHome } = props;

  return (
    <>
      <ListItem
        dense
        secondaryAction={
          !!qualification.qualified && (
            <IconButton edge="end" aria-label="certificate provided" disabled>
              <span className="material-icons-round text-success">
                verified
              </span>
            </IconButton>
          )
        }
      >
        <ListItemAvatar>
          <Avatar
            style={{
              fontSize: "16px",
              border: "1px solid #c8c6c6",
            }}
            className="bg-white text-dark "
          >
            {index + 1}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={
            <span className={`${"font-weight-light"}`}>
              {qualification.qualificationName}
            </span>
          }
          secondary={
            <>
              <span
                className={`d-none d-md-inline ${"font-weight-bold badge badge-secondary"}`}
              >
                {qualification.yearsOfExperience < 1
                  ? qualification.yearsOfExperience + " Year"
                  : qualification.yearsOfExperience + " Years"}{" "}
                of relevant experience
              </span>
              <span
                className={`d-inline-block d-md-none ${"font-weight-bold badge text-truncate badge-secondary"}`}
                style={{ maxWidth: "100px" }}
              >
                {qualification.yearsOfExperience < 1
                  ? qualification.yearsOfExperience + " Year"
                  : qualification.yearsOfExperience + " Years"}{" "}
                of relevant experience
              </span>
            </>
          }
        />
      </ListItem>

      {showDivider && <Divider />}
    </>
  );
};
