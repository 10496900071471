const translateLanguage = (textInAllLanguage, selectedLanguage = "English") => {
  return selectedLanguage === "English"
    ? textInAllLanguage.en
    : selectedLanguage === "French"
    ? textInAllLanguage.fr
    : selectedLanguage === "Kinyarwanda"
    ? textInAllLanguage.kn
    : textInAllLanguage.en;
};

export default translateLanguage;
