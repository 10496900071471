import React, { useState } from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const HandleFillMissing = ({
  selectedQuestion,
  selectedLanguage,
  submitQuestionAnswer,
  isLastQuestion,
}) => {
  // Determine which language's question to use
  const selectedQuestionTitle =
    selectedLanguage === "English"
      ? selectedQuestion.en
      : selectedLanguage === "French"
      ? selectedQuestion.fr
      : selectedQuestion.kn;

  const getOptionByLanguageSelected = (option) => {
    return selectedLanguage === "English"
      ? option.en
      : selectedLanguage === "French"
      ? option.fr
      : option.kn;
  };

  // Split the question title at each occurrence of "#_#"
  const parts = selectedQuestionTitle.split(/#\s*_\s*#/);

  // Initialize state for each answer field
  const [answers, setAnswers] = useState(Array(parts.length - 1).fill(""));

  // Handle input changes for each answer field
  const handleChange = (event, newValue) => {
    const newAnswers = [...answers];
    newAnswers[0] = getOptionByLanguageSelected(newValue);
    setAnswers(newAnswers);
    console.log({ newAnswersEvent: getOptionByLanguageSelected(newValue) });
  };

  return (
    <div style={{ fontWeight: "bold" }}>
      <React.Fragment>
        Q{selectedQuestion?.i}.
        {parts.map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < parts.length - 1 && (
              <>
                {/* <TextField
                  variant="outlined"
                  size="small"
                  placeholder="..."
                  autoFocus={index === 0} // Autofocus on the first input field
                  value={answers[index]} // Bind input field value to state
                  onChange={(event) => handleChange(index, event)} // Handle input changes
                  sx={{
                    margin: "0 8px",
                    bgcolor: "white",
                    fontWeight: "bold",
                    color: "blue",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "& input": {
                        fontWeight: "bold",
                        color: "blue",
                      },
                    },
                  }}
                /> */}
                <Autocomplete
                  options={selectedQuestion.options}
                  getOptionLabel={(option) =>
                    getOptionByLanguageSelected(option)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      variant="outlined"
                      sx={{
                        margin: "0 8px",
                        bgcolor: "white",
                        fontWeight: "bold",
                        color: "blue",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "& input": {
                            fontWeight: "bold",
                            color: "blue",
                          },
                        },
                      }}
                    />
                  )}
                  style={{ width: 300 }}
                  isOptionEqualToValue={(option, value) =>
                    option.optionNumber === value.optionNumber
                  }
                  onChange={handleChange} // Handle input changes
                />
              </>
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
      <div style={{ height: "40px" }}></div>
      <div
        className="mt-2 bg-primary pl-4 pr-4 pt-2 pb-2 rounded-pill text-white"
        style={{ width: "max-content", cursor: "pointer", marginTop: "40px" }}
        onClick={() => {
          // Submit the answers when the button is clicked
          if (answers.length > 0) {
            console.log({ newAnswers: answers });
            // Let's take the first string
            const selectedAnswer = answers[0];

            const correctOptions = selectedQuestion.options.filter(
              (itm) => itm.isCorrect === true
            );

            const totalMarks =
              correctOptions.find(
                (itm) =>
                  getOptionByLanguageSelected(itm).toLowerCase() ===
                  selectedAnswer.toLowerCase()
              ) !== undefined
                ? selectedQuestion.score
                : 0;
            submitQuestionAnswer({
              questionId: selectedQuestion.questionId,
              score: totalMarks,
            });

            console.log({ totalMarks: totalMarks });
          }
        }}
      >
        {isLastQuestion === true ? "Save & Submit the Test" : "Save & Continue"}
      </div>
    </div>
  );
};

export default HandleFillMissing;
